<template>
  <v-card class="pa-5">
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <field-card label="Logo">
        <asset-manager v-model="value.logo" />
      </field-card>
      <field-card label="Clock Widget Options">
        <m-clock-widget-form v-model="value.mClockWidgetOptions" />
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { MClockWidgetForm } from './../MClockWidget'
export default {
  name: 'MHeaderForm',
  components: {
    MClockWidgetForm
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    imageUpload: false
  })
}
</script>
